import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 添加角色
export const addRole = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/UserRole/AddRole`,params,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 修改人员信息
export const editRole = (params) => {
    return new Promise((resolve, reject) => {
        axios.put(`${host}/api/UserRole/EditRole`,params,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 删除用户
export const DeleteRole = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/UserRole/DeleteRole`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
//查询人员列表
export const getRoleList = (params) => {
    return new Promise((resolve, reject) => {  
        axios.post(`${host}/api/UserRole/GetRoles`, params ).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 查询路由列表菜单
export const getUserMenus = (params) => {
    return new Promise((resolve, reject) => {  
        axios.get(`${host}/api/UserRole/GetUserMenus?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 赋予角色菜单
export const giveRoleMenus = (params) => {
    return new Promise((resolve, reject) => {  
        axios.post(`${host}/api/UserRole/GiveRoleMenus?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 根据powerID 获取munuid 用来回显
export const getSelectMenuId = (params) => {
    return new Promise((resolve, reject) => {  
        axios.get(`${host}/api/UserRole/PorwerIdToMenuId?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}