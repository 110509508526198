import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 添加人员
export const addUser = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/UserRole/AddUser`, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 修改人员信息
export const editUser = (params) => {
    return new Promise((resolve, reject) => {
        axios.put(`${host}/api/UserRole/EditUser`, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 删除用户
export const DeleteUser = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/UserRole/DeleteUser`, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
//查询人员列表
export const getUserList = (params) => {
    return new Promise((resolve, reject) => {  
        axios.post(`${host}/api/UserRole/GetUsers`, params,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取用户全部角色
export const getAllList = (params) => {
    return new Promise((resolve, reject) => {  
        axios.post(`${host}/api/UserRole/GetRolesAll`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 赋予用户角色
export const giveUserRole = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/UserRole/GiveUserRole?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取所有小程序类型列表
export const getMpList = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/UserRole/GetAppList`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询裕民银行方的交易流水
export const GetYumiTrans = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Yumin/GetYumiTrans`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载裕民银行方的交易流水
export const DownLoadYumiTrans = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Yumin/DownLoadYumiTrans`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载电子回单
export const DownloadReceipt = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Yumin/DownloadReceipt?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}